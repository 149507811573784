.leftEditor {
  display: flex;
  justify-content: space-between;
  align-items: center; // Ensures icons are vertically aligned
  margin-right: auto;
}

.rightEditor {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // Ensures icons are vertically aligned
  margin-left: auto;
}

.buttonEditor {
  background-color: transparent !important;
  border: 0 !important;
  color: inherit; // Ensures text color remains unchanged
}

.buttonEditor:hover {
  background-color: transparent !important; // Prevents any background change
  color: inherit !important; // Ensures text color remains unchanged
  border: 0;
  cursor: pointer;
}

.headerEditor {
  top: 0;
  display: flex;
  align-items: center;
  background: var(--grey-2);
  padding: 10px;
  border-top: 1px solid gray; // Add a 2px white border at the top
}

.headerEditor a {
  padding: 4px 8px;
}

.tabActiveEditor {
  background: var(--grey-3);
}

.sidebar {
  margin: 0;
  margin-top: 50px;
  padding: 0;
  width: 32%;
  height: 100%;
  background-color: var(--grey-2);
  position: fixed;
  overflow: auto;
  right: 0;
}

.sidebarText {
  margin-top: 25px;
  text-align: center;
}

.sidebarText2 {
  margin-top: 7px;
  margin-left: 15px;
}
.buttonContainer {
  display: flex;
  justify-content: end; /* Pushes items apart */
  width: 100%; /* Ensures full width */
}

.dropdownButton {
  background-color: transparent;
  margin-bottom: 20px;
}

.dropdownButton:hover {
  background-color: transparent !important; // Prevents any background change
  color: inherit !important; // Ensures text color remains unchanged
  border: 0;
  cursor: pointer;
}

.dropdownContent {
  background: transparent;
  padding: 10px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}

.checkbox {
  border-style: solid;
  border-width: 0.5px;
  border-color: gray;
  border-radius: 0;
}

.checkbox:checked {
  background-color: aqua;
  border-radius: 0;
}

.precalcConditions {
  margin-top: 10px;
  margin-left: 20px;
}